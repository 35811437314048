import React from "react"
import { Link } from "gatsby"
import SectionLayout from "../components/SectionLayout"
import VitaItem from "../components/VitaItem"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="About me" />
    <SectionLayout bgColor="#f7f7f7">
      <h1>About me</h1>
      <p>
        My name is Alexa Steinbrück. I am a freelance software developer from
        Leipzig. (If you are looking for this kind of services, please visit my
        official website{" "}
        <a
          href="http://alexasteinbruck.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          alexasteinbruck.com
        </a>
        ). This website is about teaching technical knowledge, AI literacy,
        pedagogical approaches and more.
      </p>
      <p>
        Besides my work as a software developer I develop workshops to share my
        knowledge in the field of AI, to dispel myths and to give people
        cutting-edge technology as a creative tool.
      </p>
      <p>
        I studied Artificial Intelligence and Fine Arts. It has always been
        clear to me that code is a medium for creative expression like pen or
        brush.
      </p>
    </SectionLayout>
    <SectionLayout>
      <VitaItem timeSpan="2014 - 2020" title="Freelance software developer">
        Self-employed as software developer and creative technologist with a
        focus on frontend (Javascript) and data visualization. Several years as
        a consultant for the United Nations in New York. Data visualization
        projects for Zeit-Online. Working in Leipzig since 2018 with a focus on
        the local start-up scene.
      </VitaItem>
      <VitaItem
        timeSpan="2010 - 2013"
        title="Bachelor - Artificial Intelligence"
      >
        University of Amsterdam (The Netherlands), University Nijmegen (The
        Netherlands)
      </VitaItem>
      <VitaItem timeSpan="2006 - 2010" title="Diplom - Fine Arts">
        Hochschule für Bildende Künste in Dresden, École des Beaux Arts de
        Toulouse (France), École des Beaux Arts d'Aix-en-Provence (France)
      </VitaItem>
    </SectionLayout>
  </Layout>
)

export default SecondPage
