import React from "react"
import "./VitaItem.css"

const VitaItem = ({ timeSpan, title, children }) => (
  <div className="vita-item">
    <div className="vita-item__left">
      <span className="vita-item__left__timespan">{timeSpan}</span>
      <h2 className="vita-item__left__title">{title}</h2>
    </div>
    <div className="vita-item__right">
      <p className="vita-item__right__text">{children}</p>
    </div>
  </div>
)

export default VitaItem
